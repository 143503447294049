@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=block');
@import "./variables";

body {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  color: $text-color;
  line-height: 1.5;
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
  line-height: 1.25;
}

h1 {font-size: 3.052rem;}

h2 {font-size: 2.441rem;}

h3 {font-size: 1.953rem;}

h4 {font-size: 1.563rem;}

h5 {font-size: 1.25rem;}

.text--light {
  color: $text-color--lighter;
}
