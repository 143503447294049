@import "./variables";

$plan__padding: 2rem;

.plan-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.plan {
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: $box-shadow;
  background-color: white;
}

.plan--accent .plan__header {
  background-color: $accent-color;
}

.plan--accent-lighter .plan__header {
  background-color: $accent-color--lighter;
}

.plan__body {
  flex: 1 1 auto;
}

.plan__header {
  padding: $plan__padding;
}

.plan__list, .plan__footer {
  margin: $plan__padding;
}

.plan__footer {
  margin-top: 0;
}

@media screen and (min-width: $container--big__max-width) {
  .plan-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }

  .plan--larger {
    margin-top: -2rem;
    margin-bottom: -2rem;

    .plan__header {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    .plan__footer {
      margin-bottom: 3rem;
    }
  }
}

.plan__name {
  margin: 0 0 1rem;
}

.plan__price {
  font-size: 3rem;
  margin-right: 0.5rem;

  &::after {
    content: '€';
  }
}

.plan__button {
  display: block;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}
