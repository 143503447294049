@import "./variables";


.btn {
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  outline: none;
  border-radius: $border-radius;
  border: none;
  transition: transform 0.25s;
}

@mixin btn-background($color) {
  background-color: $color;

  &:hover {
    background-color: darken($color, 5);
  }
}

.btn--secondary {
  color: $text-color;
  @include btn-background($secondary-color);
}

.btn--primary {
  color: white;
  @include btn-background($primary-color);
}
