@import "./variables";

.container {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding-left: $container__x-padding;
  padding-right: $container__x-padding;
}

@media screen and (min-width: $container__max-width) {
  .container {
    max-width: $container__max-width !important;
  }
}

@media screen and (min-width: $container--big__max-width) {
  .container--big {
    max-width: $container--big__max-width !important;
  }
}
