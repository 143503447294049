@import "./variables";

.list {
  list-style: none;
  padding: 0;
}

.list--tick {
  list-style-image: url(../images/tick.svg);
  padding-left: 1.5em;
  color: var(--color-headings);

  & > li {
    margin: 0.5rem 0;
    padding-left: 0.25rem;
  }
}
