@import "./variables";

.collapsible__content, .collapsible__heading::after {
  transition-duration: 0.25s;
  transition-timing-function: ease;
}

.collapsible__heading {
  display: inline;

  &::after {
    content: '';
    display: inline-block;
    margin-left: 5px;
    height: 1em;
    width: 1em;
    mask: url('../images/home-iconSprite.svg#chevron-view') 50% / contain no-repeat;
    background-color: $primary-color;
    transform: rotate(0);
    transition-property: transform;
    vertical-align: text-bottom;
  }
}

.collapsible__toggle {
  cursor: pointer;
}

.collapsible__content {
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  transition-property: clip-path;
  height: 0;
  overflow: hidden;
}

.collapsible--expanded {
  .collapsible__heading::after {
    transform: rotate(180deg);
  };

  .collapsible__content {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    height: auto;
  }
}
