@import "./variables";

.icon {
  height: 24px;
  width: 24px;
}

.icon--primary {
  fill: $primary-color;
}
