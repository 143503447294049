@import "./variables";

.navbar-container, .navbar-background, .navbar-backdrop {
  height: $navbar__border-height;
}

.navbar-background {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $primary-color;
  z-index: 1;
}

.navbar-backdrop {
  position: sticky;
  top: 0;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.25);
}

.navbar-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-top: $navbar__y-padding;
  padding-bottom: $navbar__y-padding;
  position: absolute;
  inset: 0;
}

.navbar--dark {
  color: white;
}

.navbar.navbar--links {
  position: static;
  height: 100%;

  gap: 1rem;
  align-items: center;
  justify-content: flex-end;

  --clip-radius: 0px;
  clip-path: circle(var(--clip-radius) at calc(100% - $container__x-padding - $navbar__content-height / 2) 50%);
  transition: clip-path 0.25s;

  &::before {
    content: '';
    position: absolute;
    display: block;
    inset: 0;
    background-color: $accent-color--lighter;
    z-index: -1;
  }
}

.navbar-container.collapsible--expanded .navbar--links {
  --clip-radius: 100vw;
}

.navbar__icon {
  height: 100%;
  fill: currentColor;
}

.navbar__link {
  color: currentColor;
}

.navbar__heading {
  z-index: 2;
  font-size: 1.25rem;
  position: fixed;
  top: 0;
  width: 100%;
  line-height: $navbar__border-height;
  margin: 0;
  color: white;
  text-align: center;
}
