@import "./normalize.css";

@import "./typography";
@import "./container";
@import "./collapsible";
@import "./navbar";
@import "./button";
@import "./footer";
@import "./list";

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
