@import "./variables";


.feature__header {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 0.5rem;
}

.feature__heading {
  display: inline;
  margin: 0;
}

@media screen and (min-width: $container__max-width) {
  .feature:nth-of-type(odd) .picture-list {
    flex-direction: row-reverse;
  }
}
