$primary-color: #705697;
$secondary-color: #ededf5;
$accent-color: #89b262;
$accent-color--lighter: #c4d9b1;
$body-color: #f2f2f2;
$body-color--lighter: #f5f5f5;
$text-color: #333333;
$text-color--lighter: #616161;

$border-radius: 5px;
$box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);

$navbar__content-height: 2.5rem;
$navbar__y-padding: 0.5rem;
$navbar__border-height: $navbar__content-height + 2 * $navbar__y-padding;
$container__x-padding: 1rem;
$container__max-width: 768px;
$container--big__max-width: 1024px;
