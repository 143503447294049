@import "./variables";


.picture-list {
  overflow-y: hidden;
  overflow-x: scroll;

  $padding-x: calc((100vw - 100%) / 2);

  //&::-webkit-scrollbar {
  //  margin-top: -10px;
  //  height: 10px;
  //  background-color: lightgray;
  //}
  //
  //&::-webkit-scrollbar-thumb {
  //  background-color: gray;
  //}

  margin: 0 calc(#{$padding-x} * -1) 4rem;
  padding: 1rem $padding-x;
  list-style: none;
  display: flex;
  align-items: stretch;
  column-gap: 1rem;

  img {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    //content-visibility: auto;
    //contain-intrinsic-height: 500px;

    //transform: scale(1);
    //transition: transform 300ms;
    //
    //&:hover {
    //  transform: scale(1.025);
    //}
  }
}

.picture-list__item {
  flex: 0 1 auto;
}
