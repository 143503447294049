@import "./variables";


$block__skew-height: 3rem;

.block {
  position: relative;
  clip-path: var(--block-clip);
  padding-bottom: $block__skew-height;

  & + .block {
    margin-top: -$block__skew-height;
    padding-top: $block__skew-height;
  }
}

.block--striped {
  clip-path: none;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    clip-path: var(--block-clip);
    background-color: var(--block-background);
  }

  &:nth-of-type(odd) {
    --block-background: white;
  }

  &:nth-of-type(even) {
    --block-background: #{$secondary-color};
  }
}

.block--triangle-bottom {
  --block-clip: polygon(0% 0%, 100% 0%, 100% 100%, 50% calc(100% - #{$block__skew-height}), 0% 100%);
}

.block--skewed-bottom-left {
  --block-clip: polygon(0% 0%, 100% 0%, 100% calc(100% - #{$block__skew-height}), 0% 100%);
}

.block--skewed-bottom-right {
  --block-clip: polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - #{$block__skew-height}));
}

.block--skewed-top-left {
  --block-clip: polygon(0% 0%, 100% #{$block__skew-height}, 100% 100%, 0% 100%);
}

.block--skewed-top-right {
  --block-clip: polygon(0% #{$block__skew-height}, 100% 0%, 100% 100%, 0% 100%);
}

.block__heading {
  text-align: center;
  margin-bottom: 0;
}

.navbar__heading.block__heading {
  //scroll-snap-align: start;

  margin-bottom: 0.75rem;
  position: sticky;
  top: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

  &::after {
    content: '';
    display: block;
    position: fixed;
    inset: $navbar__border-height 0 0;
    background-color: var(--block-background);
  }
}
