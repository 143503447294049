@import "./variables";



.footer {
  overflow: hidden;
  background-color: #666;
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.footer__email {
  margin-top: 0.5rem;
  height: 1em;
  fill: currentColor;
  display: block;
}
