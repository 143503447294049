@import "./variables";
@import "./block";

.block.hero {
  /*
   Fix for chromium (see https://stackoverflow.com/questions/60997948/backdrop-filter-not-working-for-nested-elements-in-chrome)
   This line makes .hero the backdrop root, and thus .navbar-backdrop no longer attempts to blur .navbar-background
   Using filter instead of backdrop-filter, because the latter breaks the blur on firefox
  */
  filter: blur(0);

  position: relative;
  z-index: 2;

  min-height: 100vh;
  padding-bottom: 0;

  display: flex;
  flex-direction: column;

  color: white;
}

.hero__background {
  /*
   Because of filter property on .hero, a new bounding box is created,
   and thus 'position: fixed' is relative to hero, so we have to use sticky instead
  */
  position: sticky;
  top: 0;
  z-index: -1;

  height: 0;
}

.hero__video {
  $video-pos: 75% 100%;

  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: $video-pos;

  background-color: $primary-color;
  background-image: url('../images/hero.png?as=jpeg');
  background-size: cover;
  background-position: $video-pos;

  filter: brightness(80%);
}

@keyframes zoom-in {
  0% {
    transform: scale(.6);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.hero__content {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  padding-bottom: $block__skew-height;
  column-gap: 1rem;
  animation: zoom-in 1s cubic-bezier(.175, .885, .32, 1.275);

  .btn {
    align-self: center;
  }
}

@media screen and (min-width: $container__max-width) {
  .hero__content {
    justify-content: flex-start;
  }
}

.hero__heading {
  position: sticky;
  top: 0;
  height: 0;
}

.hero__tagline {
  flex: 1 1 100%;
  align-self: flex-start;
  margin-bottom: 0.5rem;
}

.hero__sub__tagline {
  flex: 1 1 100%;
  align-self: flex-start;
  margin-bottom: 2.5rem;
}
